<template lang="html">
  <section class="support about-us">
    <section class="slider-full">
      <div class="slide ">

        <div class="d-flex jcsb container-slide container aic">
          <div class="div-text ">
            <Waypoint>
              <h1 class="title-big -whiteC">
                {{ $t('support.intro') }}
              </h1>
            </Waypoint>
            <Waypoint>
              <p class="-whiteC">
                {{ $t('support.texto1') }}
              </p>

            </Waypoint>

            <a href="https://app.r-sami.com/" target="_blank">
              <div class="info-contact cliente contacta">

                <div>
                  <p>{{ $t('contact.soporte1') }}</p>
                </div>

                <a><img src="/img/flecha-oscura.svg"></a>
              </div>
            </a>

          </div>
          <div class="div-image">
            <Vue3Lottie :animationData="headSupport" height="auto" width="100%" :loop="1" />
          </div>
        </div>

      </div>
    </section>
    <section class="items-support">
      <Waypoint effect="fadeIn" class="container">
        <router-link :to="{ 'name': 'support-started' }" class="item" @mouseover="hover1 = true"
          @mouseleave="hover1 = false">
          <div class="img-icon">
            <Vue3Lottie v-if="hover1" :animationData="gettingStarted" height="auto" width="75px" />
            <img src="/img/icons/getting-started.svg" alt=" Getting Started"
              :class="[hover1 == false ? 'show-icon' : 'no-show-icon']">
          </div>
          <h2 class="title-semi-medium -blueDarkC">
            {{ $t('support.getting_started') }}
          </h2>
          <p class="description">
            {{ $t('support.getting_started_text') }}
          </p>
        </router-link>
        <router-link :to="{ 'name': 'support-how' }" class="item" @mouseover="hover2 = true"
          @mouseleave="hover2 = false">
          <div class="img-icon">
            <Vue3Lottie v-if="hover2" :animationData="howVideos" height="auto" width="75px" />
            <img src="/img/icons/how-videos.svg" alt="How to Videos"
              :class="[hover2 == false ? 'show-icon' : 'no-show-icon']">
          </div>
          <h2 class="title-semi-medium -blueDarkC">
            {{ $t('support.how_to_videos') }}
          </h2>
          <p class="description">
            {{ $t('support.how_to_videos_text') }}
          </p>
        </router-link>

        <router-link :to="{ 'name': 'support-release' }" class="item" @mouseover="hover3 = true"
          @mouseleave="hover3 = false">
          <div class="img-icon">
            <Vue3Lottie v-if="hover3" :animationData="releaseNotes" height="auto" width="75px" />
            <img src="/img/icons/release-notes.svg" alt="Release Notes"
              :class="[hover3 == false ? 'show-icon' : 'no-show-icon']">
          </div>
          <h2 class="title-semi-medium -blueDarkC">
            {{ $t('support.release_notes') }}
          </h2>
          <p class="description">
            {{ $t('support.release_notes_text') }}
          </p>
        </router-link>
      </Waypoint>
    </section>
    <section class="banner-footer">
      <div class="container">
        <p class="title-medium -blueDarkC"> {{ $t('support.intro5') }}</p>
        <div class="btn-blue">
          <router-link :to="{ name: 'contact' }">
            {{ $t('support.button') }}
          </router-link>
        </div>
      </div>
    </section>
  </section>
</template>

<script lang="js">
import { Vue3Lottie } from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import headSupport from '@/lotties/head-support.json'
import howVideos from '@/lotties/how-videos.json'
import releaseNotes from '@/lotties/release-notes.json'
import gettingStarted from '@/lotties/getting-started.json'

import { Waypoint } from 'vue-waypoint'
export default {
  name: 'support',
  props: [],
  components: {
    Waypoint,
    Vue3Lottie
  },
  mounted() {

  },

  data() {
    return {
      hover1: false,
      hover2: false,
      hover3: false,
      headSupport,
      gettingStarted,
      howVideos,
      releaseNotes
    }
  },
  methods: {

  },
  computed: {

  }
}


</script>

<style scoped lang="scss">

.slider-full .slide .container-slide {
  padding: 40px 20px;
}

.img-icon {
  height: 75px;

}

.banner-footer .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.support.about-us {
    margin-top: -12px;
}
.info-contact.cliente.contacta {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 50px;
  padding: 25px;
  max-width: 350px;
  border-radius: 100px;
  background-color: white !important;

  & div {
    padding: 10px 20px;
  }

  & div p+p {
    color: #373F41;
    font-size: 18px;
  }
}

.btn-blue a {
  color: white !important
}

.info-contact.cliente p {
    color: #373F41;
    font-size: 18px;
}
@media screen and (max-width:900px) {
  .about-us .slider-full .slide .container-slide .div-text {

    padding-left: 0px;
}
  .info-contact.cliente.contacta div {
    padding: 0px 20px;
  }

  .info-contact.cliente.contacta .texto-azul {
    color: #00D0CA !important;
    font-size: 12px;

    &+p {
      font-size: 16px;
    }
  }

}
@media screen and (max-width:890px){
  .slider-full .slide .container-slide{
    padding: 80px 20px;
    padding-top:155px
}
    p{
      text-align: center;
    }
  }
  @media screen and (max-width:845px){
  .support.about-us .slider-full .slide {
        background: #1E2F3F;
        position: relative;
        height: 770px;
    }
}
</style>